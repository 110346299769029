import request from '@/utils/request'


// 查询签到-主列表
export function listSignIn(query) {
  return request({
    url: '/biz/signIn/list',
    method: 'get',
    params: query
  })
}

// 查询签到-主分页
export function pageSignIn(query) {
  return request({
    url: '/biz/signIn/page',
    method: 'get',
    params: query
  })
}

// 查询签到-主详细
export function getSignIn(data) {
  return request({
    url: '/biz/signIn/detail',
    method: 'get',
    params: data
  })
}

// 新增签到-主
export function addSignIn(data) {
  return request({
    url: '/biz/signIn/add',
    method: 'post',
    data: data
  })
}

// 修改签到-主
export function updateSignIn(data) {
  return request({
    url: '/biz/signIn/edit',
    method: 'post',
    data: data
  })
}

// 删除签到-主
export function delSignIn(data) {
  return request({
    url: '/biz/signIn/delete',
    method: 'post',
    data: data
  })
}
